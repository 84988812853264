import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Place } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CardContainer from "../ModalComponents/CardContainer";
import { State } from "../../../utils/types";

interface IPersonalDetails {
  name: string;
  email: string;
  summary: string | null;
  coverLetter: string | null;
  phoneNumber: string | null;
  address: {
    cep: string | null;
    address_1: string | null;
    address_2: string | null;
    district: string | null;
    city: string | null;
    state: State;
  };
}

const PersonalDetails: React.FC<IPersonalDetails> = ({
  name,
  email,
  summary,
  coverLetter,
  phoneNumber,
  address,
}) => {
  const renderField = (name: string, value: React.ReactNode) => {
    return (
      <Grid item xs={12} md={6}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          {name}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>{value}</Typography>
      </Grid>
    );
  };

  const renderFullAddress = () => {
    if (!address) return "-";

    const fullAddress = `${address.address_1 || ""} ${
      address.address_2 || ""
    }, ${address.district || ""}, ${address.city || ""} - ${
      address.state || ""
    }, ${address.cep || ""}`;

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      fullAddress
    )}`;

    return (
      <Box sx={{ display: "flex" }}>
        <Box>
          <Place fontSize="small" />
        </Box>
        <Link
          to={googleMapsUrl}
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          {fullAddress
            .replace(/,\s*,/g, ", ")
            .replace(/^\s*,|,\s*$/g, "")
            .trim() || "-"}
        </Link>
      </Box>
    );
  };

  const renderCoverLetter = () => {
    if (!coverLetter) {
      return null;
    }

    return (
      <CardContainer title="Carta de Apresentação">
        <Typography sx={{ fontSize: 14 }}>{coverLetter}</Typography>
      </CardContainer>
    );
  };

  const renderSumary = () => {
    if (!summary) {
      return null;
    }

    return (
      <CardContainer title="Sumário Profissional">
        <Typography sx={{ fontSize: 14 }}>{summary}</Typography>
      </CardContainer>
    );
  };

  const renderAddress = () => {
    if (
      !address ||
      (!address.cep &&
        !address.address_1 &&
        !address.address_2 &&
        !address.district &&
        !address.city &&
        !address.state)
    ) {
      return null;
    }

    return (
      <CardContainer title="Endereço">
        <Grid container spacing={2}>
          {renderField("CEP", address.cep || "-")}
          {renderField("Endereço", address.address_1 || "-")}
          {renderField("Complemento", address.address_2 || "-")}
          {renderField("Bairro", address.district || "-")}
          {renderField("Cidade", address.city || "-")}
          {renderField("Estado", address.state || "-")}
          {renderField("Endereço Completo", renderFullAddress())}
        </Grid>
      </CardContainer>
    );
  };

  const renderPhoneNumberField = () => {
    if (!email) return "-";

    return (
      <Link
        to={`mailto:${email}`}
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        {email}
      </Link>
    );
  };

  const renderContactDetails = () => {
    return (
      <CardContainer title="Informações de contato">
        <Grid container spacing={2}>
          {renderField("Nome", name || "-")}
          {renderField("Email", renderPhoneNumberField())}
          {phoneNumber ? renderField("Telefone", phoneNumber) : null}
        </Grid>
      </CardContainer>
    );
  };

  return (
    <>
      {renderContactDetails()}
      {renderAddress()}
      {renderSumary()}
      {renderCoverLetter()}
    </>
  );
};

export default PersonalDetails;
