export const jobStatuses = {
  published: "Publicado",
  draft: "Rascunho",
  standby: "Em Espera",
  completed: "Finalizado",
};

export const jobStatusesForCandidate = {
  published: "Em Andamento",
  draft: "Rascunho",
  standby: "Em Espera",
  completed: "Finalizado",
};

export const userStatuses = {
  active: "Ativo",
  inactive: "Inativo",
};

export const jobStatusesOptions = [
  {
    id: "published",
    label: "Publicado",
  },
  {
    id: "standby",
    label: "Em Espera",
  },
  {
    id: "completed",
    label: "Finalizado",
  },
];

export const states = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

export const stateOptions = [
  {
    id: "AC",
    label: "Acre",
  },
  {
    id: "AL",
    label: "Alagoas",
  },
  {
    id: "AP",
    label: "Amapá",
  },
  {
    id: "AM",
    label: "Amazonas",
  },
  {
    id: "BA",
    label: "Bahia",
  },
  {
    id: "CE",
    label: "Ceará",
  },
  {
    id: "DF",
    label: "Distrito Federal",
  },
  {
    id: "ES",
    label: "Espírito Santo",
  },
  {
    id: "GO",
    label: "Goiás",
  },
  {
    id: "MA",
    label: "Maranhão",
  },
  {
    id: "MT",
    label: "Mato Grosso",
  },
  {
    id: "MS",
    label: "Mato Grosso do Sul",
  },
  {
    id: "MG",
    label: "Minas Gerais",
  },
  {
    id: "PA",
    label: "Pará",
  },
  {
    id: "PB",
    label: "Paraíba",
  },
  {
    id: "PR",
    label: "Paraná",
  },
  {
    id: "PE",
    label: "Pernambuco",
  },
  {
    id: "PI",
    label: "Piauí",
  },
  {
    id: "RJ",
    label: "Rio de Janeiro",
  },
  {
    id: "RN",
    label: "Rio Grande do Norte",
  },
  {
    id: "RS",
    label: "Rio Grande do Sul",
  },
  {
    id: "RO",
    label: "Rondônia",
  },
  {
    id: "RR",
    label: "Roraima",
  },
  {
    id: "SC",
    label: "Santa Catarina",
  },
  {
    id: "SP",
    label: "São Paulo",
  },
  {
    id: "SE",
    label: "Sergipe",
  },
  {
    id: "TO",
    label: "Tocantins",
  },
];

export const workingTypes = {
  clt: "CLT",
  pj: "PJ",
  freelancer: "Freelancer",
  part_time_work: "Trabalho Parcial",
  internship: "Estágio",
  apprentice: "Jovem Aprendiz",
  intermittent_work: "Trabalho Intermitente",
  temporary_work: "Trabalho Temporário",
  to_be_determined: "A combinar",
};

export const workingTypeOptions = [
  {
    id: "clt",
    label: "CLT",
  },
  {
    id: "pj",
    label: "PJ",
  },
  {
    id: "freelancer",
    label: "Freelancer",
  },
  {
    id: "part_time_work",
    label: "Trabalho Parcial",
  },
  {
    id: "internship",
    label: "Estágio",
  },
  {
    id: "apprentice",
    label: "Jovem Aprendiz",
  },
  {
    id: "intermittent_work",
    label: "Trabalho Intermitente",
  },
  {
    id: "temporary_work",
    label: "Trabalho Temporário",
  },
  {
    id: "to_be_determined",
    label: "A combinar",
  },
];

export const workingModels = {
  on_site: "Presencial",
  hybrid: "Híbrido",
  remote: "Remoto",
};

export const workingModelOptions = [
  {
    id: "on_site",
    label: "Presencial",
  },
  {
    id: "hybrid",
    label: "Híbrido",
  },
  {
    id: "remote",
    label: "Remoto",
  },
];

export const userStatusOptions = [
  {
    id: "active",
    label: "Ativo",
  },
  {
    id: "inactive",
    label: "Inativo",
  },
];

export const questionTypes = {
  short_answer: "Resposta curta",
  long_answer: "Resposta longa",
  yes_no: "Sim / Não",
  number: "Número",
  date: "Data",
  file: "Arquivo",
};

export const questionTypeOptions = [
  {
    id: "short_answer",
    label: "Resposta curta",
  },
  {
    id: "long_answer",
    label: "Resposta longa",
  },
  {
    id: "yes_no",
    label: "Sim / Não",
  },
  {
    id: "number",
    label: "Número",
  },
  {
    id: "date",
    label: "Data",
  },
];

export const plans: { [key: string]: string } = {
  free: "Gratuito",
  standard: "Padrão",
  enterprise: "Corporativo",
};

export const permissionNames: { [key: string]: string } = {
  "jobs.index": "Vagas",
  "jobs.show": "Visualizar",
  "jobs.show_all": "Visualizar todas as vagas",
  "jobs.create": "Adicionar",
  "jobs.update": "Editar",
  "jobs.destroy": "Excluir",
  "billings.index": "Cobranças",
  "resume_database.index": "Banco de Currículos",
  "company.show": "Website",
  "company.update": "Editar",
  "departments.index": "Departamentos",
  "departments.show": "Visualizar",
  "departments.create": "Adicionar",
  "departments.update": "Editar",
  "departments.destroy": "Excluir",
  "benefits.index": "Benefícios",
  "benefits.show": "Visualizar",
  "benefits.create": "Adicionar",
  "benefits.update": "Editar",
  "benefits.destroy": "Excluir",
  "profiles.index": "Perfis",
  "profiles.show": "Visualizar",
  "profiles.create": "Adicionar",
  "profiles.update": "Editar",
  "profiles.destroy": "Excluir",
  "users.index": "Usuários",
  "users.show": "Visualizar",
  "users.update": "Editar",
  "users.invite": "Convidar",
};

export const permissionHelpers: { [key: string]: string } = {
  "jobs.index": "Pode visualizar a tabela de vagas",
  "jobs.show": "Pode visualizar o item da vaga e os candidatos",
  "jobs.show_all": "Pode visualizar todos os itens da vaga",
  "jobs.create": "Pode adicionar uma vaga",
  "jobs.update":
    "Pode editar, publicar e marcar como rascunho em qualquer vaga",
  "jobs.destroy": "Pode excluir qualquer vaga",
  "billings.index": "Pode visualizar cobranças",
  "resume_database.index": "Pode visualizar banco de currículos",
  "company.show": "Pode visualizar a configuração do Website",
  "company.update": "Pode editar a configuração do Website",
  "departments.index": "Pode visualizar a tabela de departamentos",
  "departments.show": "Pode visualizar o item do departamento",
  "departments.create": "Pode adicionar um departamento",
  "departments.update": "Pode editar qualquer departamento",
  "departments.destroy": "Pode excluir qualquer departamento",
  "benefits.index": "Pode visualizar a tabela de benefícios",
  "benefits.show": "Pode visualizar o item do benefício",
  "benefits.create": "Pode adicionar um benefício",
  "benefits.update": "Pode editar qualquer benefício",
  "benefits.destroy": "Pode excluir qualquer benefício",
  "profiles.index": "Pode visualizar a tabela de perfis",
  "profiles.show": "Pode visualizar o item do perfil",
  "profiles.create": "Pode adicionar um perfil",
  "profiles.update": "Pode editar qualquer perfil",
  "profiles.destroy": "Pode excluir qualquer perfil",
  "users.index": "Pode visualizar a tabela de usuários",
  "users.show": "Pode visualizar o item do usuário",
  "users.update": "Pode editar qualquer usuário",
  "users.invite": "Pode convidar um usuário para participar da empresa",
};
