import React, { useState } from "react";
import {
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  Phone,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Box, Snackbar, Tooltip, useTheme } from "@mui/material";
import tiktok_logo from "./../../assets/images/tiktok_logo.png";
import CopyToClipboard from "react-copy-to-clipboard";

const LINKEDIN_BASE_URL = "https://www.linkedin.com/in/";
const FACEBOOK_BASE_URL = "https://www.facebook.com/";
const INSTAGRAM_BASE_URL = "https://www.instagram.com/";
const TIKTOK_BASE_URL = "https://www.tiktok.com/@";
const GITHUB_BASE_URL = "https://www.github.com/";

interface ISocialLinks {
  phoneNumber: string | null;
  linkedin: string | null;
  facebook: string | null;
  instagram: string | null;
  tiktok: string | null;
  github: string | null;
}

const SocialLinks: React.FC<ISocialLinks> = ({
  phoneNumber,
  linkedin,
  facebook,
  instagram,
  tiktok,
  github,
}) => {
  const theme = useTheme();

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const handleCopyPhoneNumber = () => {
    setSnackbarOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
      }}
    >
      {phoneNumber && (
        <>
          <Box>
            <CopyToClipboard text={phoneNumber} onCopy={handleCopyPhoneNumber}>
              <Tooltip title={phoneNumber}>
                <Phone
                  sx={{
                    cursor: "pointer",
                    color: theme.palette.common.black,
                  }}
                  fontSize="small"
                />
              </Tooltip>
            </CopyToClipboard>

            <Snackbar
              message="Telefone Copiado"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              autoHideDuration={2000}
              onClose={() => setSnackbarOpen(false)}
              open={snackbarOpen}
            />
          </Box>
        </>
      )}
      {linkedin && (
        <Link to={`${LINKEDIN_BASE_URL}${linkedin}`} target="_blank">
          <LinkedIn
            sx={{
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
            fontSize="small"
          />
        </Link>
      )}
      {facebook && (
        <Link to={`${FACEBOOK_BASE_URL}${facebook}`} target="_blank">
          <Facebook
            sx={{
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
            fontSize="small"
          />
        </Link>
      )}
      {instagram && (
        <Link to={`${INSTAGRAM_BASE_URL}${instagram}`} target="_blank">
          <Instagram
            sx={{
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
            fontSize="small"
          />
        </Link>
      )}
      {tiktok && (
        <Link to={`${TIKTOK_BASE_URL}${tiktok}`} target="_blank">
          <img
            src={tiktok_logo}
            width="18px"
            height="18px"
            style={{
              marginTop: "1px",
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
          />
        </Link>
      )}
      {github && (
        <Link to={`${GITHUB_BASE_URL}${github}`} target="_blank">
          <GitHub
            sx={{
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
            fontSize="small"
          />
        </Link>
      )}
    </Box>
  );
};

export default SocialLinks;
