import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";
import {
  Form,
  FormQuestionResponse,
  JobStatus,
  PipelineTemplate,
  Requirements,
  State,
  WorkingModel,
  WorkingType,
} from "../../utils/types";
import { DepartmentIndexAttributes } from "../department/departmentApi";
import { BenefitIndexAttributes } from "../benefit/benefitApi";
import { Education } from "../../components/MultiInput/Education";
import { WorkExperience } from "../../components/MultiInput/WorkExperience";

export interface JobIndexAttributes {
  id: number;
  name: string;
  status: JobStatus;
  working_type: WorkingType;
  working_model: WorkingModel;
  candidates_count: number;
}

export interface JobsMetrics {
  total_published_jobs: number;
  total_candidates: number;
  total_candidates_in_last_48h: number;
}

export interface JobIndexResponse extends ResponseWithErrors {
  records: JobIndexAttributes[];
  metrics: JobsMetrics;
}

export interface JobParams {
  id: number | null;
  name: string | null;
  status: JobStatus;
  working_type: WorkingType;
  working_type_visible: boolean;
  working_model: WorkingModel;
  state: State;
  city: string | null;
  description: string | null;
  step: number | null;
  internal_id: string | null;
  is_pwd: boolean;
  minimum_salary_range: string | null;
  maximum_salary_range: string | null;
  salary_range_visible: boolean;
  department_visible: boolean;
  department: DepartmentIndexAttributes | null;
  department_id: number | null;
  requirements: Requirements[];
  requirements_visible: boolean;
  benefits: BenefitIndexAttributes[];
  benefit_ids: number[];
  benefits_visible: boolean;
  users: {
    id: number;
    name: string;
    disabled: boolean;
  }[];
  user_ids: number[];
  form: Form;
  pipeline_template: PipelineTemplate;
  job_pipelines_attributes: {
    id: number | null;
    name: string;
    order: number;
    _destroy?: boolean;
    static: boolean;
  }[];
}

export interface JobShowResponse {
  id: number;
  name: string | null;
  status: JobStatus;
  working_type: WorkingType;
  working_type_visible: boolean;
  working_model: WorkingModel;
  state: State;
  city: string | null;
  description: string | null;
  published_at: Date | null;
  step: number | null;
  internal_id: string | null;
  is_pwd: boolean;
  minimum_salary_range: string | null;
  maximum_salary_range: string | null;
  salary_range_visible: boolean;
  department_visible: boolean;
  candidates_count: number;
  department: DepartmentIndexAttributes | null;
  requirements: Requirements[];
  requirements_visible: boolean;
  benefits: BenefitIndexAttributes[];
  benefits_visible: boolean;
  users: {
    id: number;
    name: string;
    disabled: boolean;
  }[];
  company: {
    name: string | null;
    slogan: string | null;
    logo: string | null;
    banner: string | null;
    primary_color: string | null;
  };
  form: Form;
  owner: {
    id: number;
    name: string;
  };
  pipeline_template: PipelineTemplate;
  job_pipelines: {
    id: number;
    name: string;
    order: number;
    static: boolean;
  }[];
}

interface JobNewResponse {
  users: {
    id: number;
    name: string;
    disabled: boolean;
  }[];
}

interface JobPipelinesResponse extends ResponseWithErrors {
  records: JobPipelinesAttributes[];
}

export interface JobPipelinesAttributes {
  id: number;
  name: string;
  order: number;
  candidates: {
    id: number;
    name: string;
    email: string;
    rating: number;
    rank_order: number;
    summary: string | null;
    cover_letter: string | null;
    phone_number: string | null;
    linkedin: string | null;
    facebook: string | null;
    instagram: string | null;
    tiktok: string | null;
    github: string | null;
    address: {
      cep: string | null;
      address_1: string | null;
      address_2: string | null;
      district: string | null;
      city: string | null;
      state: State;
    };
    educations: Education[];
    work_experiences: WorkExperience[];
    questions: FormQuestionResponse[];
    resume: { name: string; url: string } | null;
    avatar: string | null;
  }[];
}

export interface JobResponse extends JobShowResponse, ResponseWithErrors {}

interface GenerateRequirementsResponse {
  requirements: Requirements[];
}

const index = async (
  company_id: number
): Promise<AxiosResponse<JobIndexResponse>> => {
  return await requestHelper
    .get(`/jobs/${company_id}/list`)
    .catch((e) => e.response);
};

const newJob = async (): Promise<AxiosResponse<JobNewResponse>> => {
  return await requestHelper.get(`/jobs`).catch((e) => e.response);
};

const create = async (
  params: JobParams
): Promise<AxiosResponse<JobResponse>> => {
  return await requestHelper
    .post(`jobs`, {
      job: params,
    })
    .catch((e) => e.response);
};

const show = async (id: string): Promise<AxiosResponse<JobShowResponse>> => {
  return await requestHelper.get(`jobs/${id}`).catch((e) => e.response);
};

const update = async (
  params: JobParams
): Promise<AxiosResponse<JobResponse>> => {
  return await requestHelper
    .patch(`jobs/${params.id}`, {
      job: params,
    })
    .catch((e) => e.response);
};

const destroy = async (id: string): Promise<AxiosResponse<JobShowResponse>> => {
  return await requestHelper.delete(`jobs/${id}`).catch((e) => e.response);
};

const publish = async (id: string): Promise<AxiosResponse<JobResponse>> => {
  return await requestHelper
    .patch(`jobs/${id}/publish`)
    .catch((e) => e.response);
};

const updateStatus = async (
  id: string,
  status: string
): Promise<AxiosResponse<JobResponse>> => {
  return await requestHelper
    .patch(`jobs/${id}/update-status`, {
      status,
    })
    .catch((e) => e.response);
};

const pipelines = async (
  id: string
): Promise<AxiosResponse<JobPipelinesResponse>> => {
  return await requestHelper
    .get(`jobs/${id}/pipelines`)
    .catch((e) => e.response);
};

const generateDescription = async (
  id: string
): Promise<AxiosResponse<JobResponse>> => {
  return await requestHelper
    .get(`jobs/${id}/generate-description`)
    .catch((e) => e.response);
};

const generateRequirements = async (
  id: string
): Promise<AxiosResponse<GenerateRequirementsResponse>> => {
  return await requestHelper
    .get(`jobs/${id}/generate-requirements`)
    .catch((e) => e.response);
};

export {
  index,
  newJob,
  create,
  show,
  update,
  destroy,
  publish,
  updateStatus,
  pipelines,
  generateDescription,
  generateRequirements,
};
