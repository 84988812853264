import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";
import { JobStatus, State } from "../../utils/types";
import { Education } from "../../components/MultiInput/Education";
import { WorkExperience } from "../../components/MultiInput/WorkExperience";

export interface ResumeDatabaseIndexAttributes {
  id: number;
  name: string;
  email: string;
  summary: string | null;
  cover_letter: string | null;
  phone_number: string | null;
  linkedin: string | null;
  facebook: string | null;
  instagram: string | null;
  tiktok: string | null;
  github: string | null;
  address: {
    cep: string | null;
    address_1: string | null;
    address_2: string | null;
    district: string | null;
    city: string | null;
    state: State;
  };
  educations: Education[];
  work_experiences: WorkExperience[];
  resume: { name: string; url: string } | null;
  avatar: string | null;
  job: {
    id: number;
    name: string;
    status: JobStatus;
    deleted: boolean;
  };
}

export interface ResumeDatabaseIndexResponse extends ResponseWithErrors {
  records: ResumeDatabaseIndexAttributes[];
}

const index = async (): Promise<AxiosResponse<ResumeDatabaseIndexResponse>> => {
  return await requestHelper.get(`/resume-databases`).catch((e) => e.response);
};

export { index };
