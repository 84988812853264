import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";
import { UserAttributes } from "./userSlice";
import { getToken } from "../../utils/localStorage";
import { State, UserStatus } from "../../utils/types";
import { Education } from "../../components/MultiInput/Education";
import { WorkExperience } from "../../components/MultiInput/WorkExperience";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export interface UserIndexAttributes {
  id: number;
  name: string;
  email: string;
  status: UserStatus;
  is_owner: boolean;
  is_invited: boolean;
  profile: {
    id: number;
    name: string;
  };
  disabled?: boolean;
}

export interface UserIndexResponse extends ResponseWithErrors {
  records: UserIndexAttributes[];
}

export interface UserShowResponse extends UserIndexAttributes {
  first_name: string;
  last_name: string;
  permission_ids: Array<number>;
}

export interface UserParams {
  profile_id: number | "Personalizado" | null;
  permission_ids: Array<number>;
}

export interface UserInviteParams {
  first_name: string;
  last_name: string;
  email: string;
  status: UserStatus;
  profile_id: number | "Personalizado" | null;
  permission_ids: Array<number>;
}

export interface UserAcceptInvitationParams {
  first_name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
}

export interface UserCandidateParams {
  avatar: File | string | null;
  resume: File | { name: string; url: string } | null;
  resume_delete: boolean;
  first_name: string;
  last_name: string;
  phone_number: string | null;
  cep: string | null;
  address_1: string | null;
  address_2: string | null;
  district: string | null;
  city: string | null;
  state: State;
  summary: string | null;
  cover_letter: string | null;
  linkedin: string | null;
  instagram: string | null;
  tiktok: string | null;
  github: string | null;
  facebook: string | null;
  educations_attributes: Education[];
  work_experiences_attributes: WorkExperience[];
}

export interface UserResponse extends UserShowResponse, ResponseWithErrors {}

export interface UserInviteResponse extends ResponseWithErrors {}

export interface UserReinviteResponse extends ResponseWithErrors {}

export interface UserGetInvitationResponse extends ResponseWithErrors {
  is_new: boolean;
  has_password: boolean;
  company: {
    id: number;
    name: string;
    logo: string | null;
  };
}

export interface UserAcceptInvitationResponse extends ResponseWithErrors {}

export interface AuthenticateParams {
  email: string;
  password: string;
  is_candidate?: boolean;
}

export interface AuthenticateResponse extends ResponseWithErrors {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  created_at: number;
}

export interface SignUpParams {
  company_name: string;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
}

export interface CandidateSignUpParams {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
}

export interface SignUpResponse extends ResponseWithErrors {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  created_at: number;
}

const index = async (): Promise<AxiosResponse<UserIndexResponse>> => {
  return await requestHelper.get(`/users`).catch((e) => e.response);
};

const show = async (id: string): Promise<AxiosResponse<UserShowResponse>> => {
  return await requestHelper.get(`users/${id}`).catch((e) => e.response);
};

const update = async (
  userId: string,
  params: UserParams
): Promise<AxiosResponse<UserResponse>> => {
  return await requestHelper
    .patch(`users/${userId}`, {
      user: params,
    })
    .catch((e) => e.response);
};

const invite = async (
  params: UserInviteParams
): Promise<AxiosResponse<UserInviteResponse>> => {
  return await requestHelper
    .post(`users/invite`, {
      user: params,
    })
    .catch((e) => e.response);
};

const reinvite = async (
  userId: string
): Promise<AxiosResponse<UserReinviteResponse>> => {
  return await requestHelper
    .post(`users/reinvite/${userId}`)
    .catch((e) => e.response);
};

const getInvitation = async (
  token: string
): Promise<AxiosResponse<UserGetInvitationResponse>> => {
  return await requestHelper
    .get(`users/invite/${token}`)
    .catch((e) => e.response);
};

const acceptInvitation = async (
  token: string,
  params: UserAcceptInvitationParams
): Promise<AxiosResponse<UserAcceptInvitationResponse>> => {
  return await requestHelper
    .post(`users/invite/${token}`, params)
    .catch((e) => e.response);
};

const me = async (): Promise<AxiosResponse<UserAttributes>> => {
  return await requestHelper.get("users/me").catch((e) => e.response);
};

const updateMyCurrentCompany = async (
  companyId: number | null
): Promise<AxiosResponse<Record<string, never>>> => {
  return await requestHelper
    .patch(`users/update-my-current-company`, { company_id: companyId })
    .catch((e) => e.response);
};

const transferOwnership = async (
  userId: string
): Promise<AxiosResponse<UserAttributes>> => {
  return await requestHelper
    .patch(`users/${userId}/transfer-ownership`)
    .catch((e) => e.response);
};

const updateCandidate = async (
  formData: FormData
): Promise<AxiosResponse<UserAttributes>> => {
  return await requestHelper
    .patch(`users/candidate`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .catch((e) => e.response);
};

const authenticate = async (
  params: AuthenticateParams
): Promise<AxiosResponse<AuthenticateResponse>> => {
  return await requestHelper
    .post("/oauth/token", {
      ...params,
      grant_type: "password",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    })
    .catch((e) => e.response);
};

const refreshToken = async (
  refreshToken: string | null
): Promise<AxiosResponse<AuthenticateResponse>> => {
  return await requestHelper
    .post("/oauth/token", {
      grant_type: "password",
      refresh_token: refreshToken,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    })
    .catch((e) => e.response);
};

const signUp = async (
  params: SignUpParams
): Promise<AxiosResponse<SignUpResponse>> => {
  return await requestHelper
    .post("/users", {
      ...params,
      client_id: CLIENT_ID,
    })
    .catch((e) => e.response);
};

const candidateSignUp = async (
  params: CandidateSignUpParams
): Promise<AxiosResponse<SignUpResponse>> => {
  return await requestHelper
    .post("/users/candidate", {
      ...params,
      client_id: CLIENT_ID,
    })
    .catch((e) => e.response);
};

const logout = async (): Promise<AxiosResponse<Record<string, never>>> => {
  const token = getToken();

  return await requestHelper
    .post("/oauth/revoke", {
      token: token?.access_token,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    })
    .catch((e) => e.response);
};

export {
  index,
  show,
  update,
  invite,
  reinvite,
  getInvitation,
  acceptInvitation,
  me,
  updateMyCurrentCompany,
  transferOwnership,
  updateCandidate,
  authenticate,
  refreshToken,
  signUp,
  candidateSignUp,
  logout,
};
