import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  colors,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../app/store";
import { jobSelector } from "../redux/job/jobSlice";
import { Link, useLocation, useParams } from "react-router-dom";
import { getPipelines, showJob } from "../redux/job/jobActions";
import Backdrop from "../components/Backdrop";
import usePermission from "../hooks/usePermission";
import { LoadingButton } from "@mui/lab";
import { texts } from "../utils/texts";
import urls from "../utils/urls";
import { Download, Email, MoreVert, Share } from "@mui/icons-material";
import MenuDropdown, {
  IMenuDropdownRef,
  MenuItemDropdown,
} from "../components/MenuDropdown";
import { updateCandidate } from "../redux/candidate/candidateActions";
import { useFormik } from "formik";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { JobPipelinesAttributes } from "../redux/job/jobApi";
import CandidateModal from "../components/Candidate/Modal";
import SocialLinks from "../components/Candidate/SocialLinks";
import Rating from "../components/Candidate/Rating";
import useViewportHeight from "../hooks/useViewportHeight";
import CopyToClipboard from "react-copy-to-clipboard";

const CandidateJobs = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { getTobBarHeight } = useViewportHeight();

  const containerRef = useRef<HTMLDivElement>(null);
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const moreRefs = useRef<{ [key: number]: IMenuDropdownRef | null }>({});
  const { id } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const candidateId = searchParams.get("candidate_id");

  const [candidateSelectedData, setCandidateSelectedData] = useState<{
    candidate: JobPipelinesAttributes["candidates"][number];
    pipelineIndex: number;
    candidateIndex: number;
  } | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const hasPermissionToJobsUpdate = usePermission("jobs.update");

  const jobState = useAppSelector(jobSelector);

  useEffect(() => {
    if (id) {
      dispatch(showJob(id.toString()));
      dispatch(getPipelines(id.toString()));
    }
  }, [id]);

  useEffect(() => {
    if (jobState.pipelines.length > 0 && candidateId) {
      jobState.pipelines.map((pipeline, pipelineIndex) => {
        const candidateIndex = pipeline.candidates.findIndex(
          (candidate) => candidate.id === parseInt(candidateId)
        );
        if (candidateIndex !== -1) {
          const candidate = pipeline.candidates[candidateIndex];
          setCandidateSelectedData({
            candidate,
            pipelineIndex,
            candidateIndex,
          });
        }
        return pipeline;
      });
    }
  }, [jobState.pipelines, candidateId]);

  const formik = useFormik({
    initialValues: {
      pipelines: jobState.pipelines || [],
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return;
    }

    const jobPipelineIdDestination = parseInt(
      result.destination.droppableId.replace("pipeline-", "")
    );
    const jobPipelineIdSource = parseInt(
      result.source.droppableId.replace("pipeline-", "")
    );
    const candidateId = parseInt(result.draggableId);
    const rankOrderPosition = result.destination.index;
    dispatch(
      updateCandidate({
        params: {
          id: candidateId,
          rank_order_position: rankOrderPosition,
          job_pipeline_id: jobPipelineIdDestination,
        },
      })
    );

    const isSamePipeline =
      result.source.droppableId === result.destination.droppableId;
    let pipelines = Array.from(formik.values.pipelines);

    if (isSamePipeline) {
      pipelines = pipelines.map((pipeline) => {
        if (pipeline.id === jobPipelineIdDestination) {
          const candidates = Array.from(pipeline.candidates);
          const [candidate] = candidates.splice(result.source.index, 1);
          candidates.splice(rankOrderPosition, 0, candidate);
          return {
            ...pipeline,
            candidates,
          };
        }
        return pipeline;
      });
    } else {
      pipelines = pipelines.map((pipeline) => {
        if (pipeline.id === jobPipelineIdSource) {
          const candidates = Array.from(pipeline.candidates);
          candidates.splice(result.source.index, 1);
          return {
            ...pipeline,
            candidates,
          };
        } else if (pipeline.id === jobPipelineIdDestination) {
          const candidates = Array.from(pipeline.candidates);
          const candidate = pipelines
            .find((p) => p.id === jobPipelineIdSource)
            ?.candidates.find((c) => c.id === candidateId);
          if (candidate) {
            candidates.splice(rankOrderPosition, 0, candidate);
          }
          return {
            ...pipeline,
            candidates,
          };
        }
        return pipeline;
      });
    }

    formik.setFieldValue("pipelines", pipelines);
  };

  const downloadFile = async (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    candidate: JobPipelinesAttributes["candidates"][number]
  ) => {
    e.preventDefault();
    if (candidate.resume) {
      const response = await fetch(candidate.resume.url);
      const blob = await response.blob();
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", candidate.resume.name);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  };

  const candidateProfileUrl = (candidateId: number) => {
    if (!id) {
      return "";
    }

    return `${window.location.origin}${urls.candidatesJob.replace(
      ":id",
      id.toString()
    )}?candidate_id=${candidateId}`;
  };

  const handleCopyUrl = () => {
    setSnackbarOpen(true);
  };

  const jobIsCompleted = () => {
    return jobState?.job?.status === "completed";
  };

  return (
    <Box>
      {jobState.isFinding || jobState.isFetchingPipelines ? (
        <Backdrop />
      ) : (
        <>
          <CandidateModal
            candidateData={candidateSelectedData}
            candidateFormik={formik}
            jobIsCompleted={jobIsCompleted()}
            onClose={() => setCandidateSelectedData(null)}
          />
          <Snackbar
            message="Link Copiado"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
            open={snackbarOpen}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 4,
              width: "100%",
            }}
          >
            <Typography variant="h5">{jobState.job?.name}</Typography>
            <Tooltip
              title={!hasPermissionToJobsUpdate ? texts.notAuthorized : ""}
            >
              <Box>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  size="large"
                  disabled={!hasPermissionToJobsUpdate}
                >
                  <Link
                    to={urls.manageJob.replace(":id", id?.toString() || "")}
                  >
                    Atualizar vaga
                  </Link>
                </LoadingButton>
              </Box>
            </Tooltip>
          </Box>

          <DragDropContext onDragEnd={onDragEnd}>
            <Box
              ref={containerRef}
              sx={{
                display: "flex",
                gap: 2,
                mt: 4,
                overflowX: "auto",
                overflowY: "hidden",
                height: `calc(100vh - 200px - ${getTobBarHeight()}px)`,
                pb: 1,
              }}
            >
              {formik.values.pipelines.map((pipeline, pipelineIndex) => (
                <Box
                  sx={{
                    backgroundColor: colors.grey[100],
                    borderRadius: 2,
                    width: "280px",
                    flexShrink: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      px: 2,
                      py: 1,
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      fontSize={20}
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {pipeline.name}
                    </Typography>
                    <Box
                      sx={{
                        ml: 1,
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.common.white,
                        height: "20px",
                        minWidth: "20px",
                        borderRadius: "10px",
                        padding: "0 6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxSizing: "border-box",
                        lineHeight: 1,
                        flexShrink: 0,
                      }}
                    >
                      <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                        {pipeline.candidates.length}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Droppable
                    key={pipeline.id}
                    droppableId={`pipeline-${pipeline.id}`}
                  >
                    {(provided, snapshot) => (
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{
                          overflowY: "auto",
                          height: `calc(100vh - 262px - ${getTobBarHeight()}px)`,
                        }}
                      >
                        <Box
                          ref={cardContainerRef}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            p: 1,
                          }}
                        >
                          {pipeline.candidates.map(
                            (candidate, candidateIndex) => (
                              <Draggable
                                key={candidate.id}
                                draggableId={candidate.id?.toString()}
                                index={candidateIndex}
                                isDragDisabled={jobIsCompleted()}
                              >
                                {(provided, snapshot) => (
                                  <Card
                                    ref={provided.innerRef}
                                    sx={{ width: "100%", overflow: "initial" }}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: 0.5,
                                        pt: 2,
                                        pl: 2,
                                        pr: 0.5,
                                      }}
                                    >
                                      <Tooltip
                                        title={candidate.name}
                                        placement="top"
                                      >
                                        <Typography
                                          sx={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            setCandidateSelectedData({
                                              candidate,
                                              pipelineIndex,
                                              candidateIndex,
                                            })
                                          }
                                        >
                                          {candidate.name}
                                        </Typography>
                                      </Tooltip>

                                      <IconButton
                                        size="small"
                                        onClick={(e) =>
                                          moreRefs?.current[candidate.id]?.open(
                                            e
                                          )
                                        }
                                      >
                                        <MoreVert />
                                      </IconButton>

                                      <MenuDropdown
                                        id={`more-candidate-data-${candidate.id}`}
                                        ref={(el) =>
                                          (moreRefs.current[candidate.id] = el)
                                        }
                                      >
                                        {candidate.resume ? (
                                          <MenuItemDropdown
                                            component="a"
                                            href={candidate.resume.url}
                                            onClick={(e) =>
                                              downloadFile(e, candidate)
                                            }
                                          >
                                            <ListItemIcon>
                                              <Download fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>
                                              Baixar Currículo
                                            </ListItemText>
                                          </MenuItemDropdown>
                                        ) : null}
                                        <MenuItemDropdown
                                          component="a"
                                          href={`mailto:${candidate.email}`}
                                          target="_blank"
                                        >
                                          <ListItemIcon>
                                            <Email fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText>
                                            Enviar E-mail
                                          </ListItemText>
                                        </MenuItemDropdown>
                                        <CopyToClipboard
                                          text={candidateProfileUrl(
                                            candidate.id
                                          )}
                                          onCopy={handleCopyUrl}
                                        >
                                          <MenuItemDropdown>
                                            <ListItemIcon>
                                              <Share fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>
                                              Compartilhar Perfil
                                            </ListItemText>
                                          </MenuItemDropdown>
                                        </CopyToClipboard>
                                      </MenuDropdown>
                                    </Box>

                                    <Box sx={{ px: 2, pb: 2 }}>
                                      <SocialLinks
                                        phoneNumber={candidate.phone_number}
                                        linkedin={candidate.linkedin}
                                        facebook={candidate.facebook}
                                        instagram={candidate.instagram}
                                        tiktok={candidate.tiktok}
                                        github={candidate.github}
                                      />

                                      <Box sx={{ mt: 1 }}>
                                        <Rating
                                          formik={formik}
                                          candidate={candidate}
                                          pipelineIndex={pipelineIndex}
                                          candidateIndex={candidateIndex}
                                          disabled={jobIsCompleted()}
                                        />
                                      </Box>
                                    </Box>
                                  </Card>
                                )}
                              </Draggable>
                            )
                          )}
                        </Box>
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>
              ))}
            </Box>
          </DragDropContext>
        </>
      )}
    </Box>
  );
};

export default CandidateJobs;
