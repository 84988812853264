import React, { useCallback, useEffect, useRef, useState } from "react";
import { JobPipelinesAttributes } from "../../../redux/job/jobApi";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { Box, FormControl, FormGroup } from "@mui/material";
import useViewportHeight from "../../../hooks/useViewportHeight";
import { userCompanyNoteSelector } from "../../../redux/userCompanyNote/userCompanyNoteSlice";
import { FormikHelpers, useFormik } from "formik";
import { userSelector } from "../../../redux/user/userSlice";
import { UserCompanyNoteParams } from "../../../redux/userCompanyNote/userCompanyNoteApi";
import Editor from "../../Editor";
import { createOrUpdateUserCompanyNote } from "../../../redux/userCompanyNote/userCompanyNoteActions";
import { debounce } from "lodash";

interface INotes {
  id: number;
}

const Notes: React.FC<INotes> = ({ id }) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const userCompanyNoteState = useAppSelector(userCompanyNoteSelector);
  const userState = useAppSelector(userSelector);

  const { offsetTop } = useViewportHeight(containerRef);

  const [editorKey, setEditorKey] = useState<number>(1);

  useEffect(() => {
    setEditorKey(Math.floor(Math.random() * 100000) + 1); // this forces the Editor reinitialization, when the user back to the tab
  }, []);

  const debouncedSaveNote = useCallback(
    debounce(
      (
        values: UserCompanyNoteParams,
        formikBag: FormikHelpers<UserCompanyNoteParams>
      ) => {
        dispatch(createOrUpdateUserCompanyNote({ params: values, formikBag }));
      },
      800
    ),
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      note: userCompanyNoteState.userCompanyNote?.note,
      candidate_id: id,
      company_id: userState.currentUser.company?.id,
    } as UserCompanyNoteParams,
    onSubmit: (values, formikBag) => {
      debouncedSaveNote(values, formikBag);
    },
    enableReinitialize: true,
  });

  return (
    <Box
      ref={containerRef}
      sx={{
        height: `calc(100vh - ${offsetTop + 20}px)`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormGroup>
        <FormControl fullWidth>
          <Editor
            key={editorKey}
            id="note"
            label=""
            value={formik.values.note || ""}
            onEditorChange={(newValue, editor) => {
              formik.setFieldValue("note", newValue);
              formik.submitForm();
            }}
            height={`calc(100vh - ${offsetTop + 20}px)`}
          />
        </FormControl>
      </FormGroup>
    </Box>
  );
};

export default Notes;
