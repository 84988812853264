import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";
import * as userApi from "./userApi";
import { clearLocalStorage, saveToken } from "../../utils/localStorage";
import statusCodes from "../../utils/statusCodes";
import setFormikErrors from "../../utils/setFormikErrors";

export const getUsers = createAsyncThunk(
  "users/index",
  async (_params, thunkAPI) => {
    try {
      const response = await userApi.index();
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const showUser = createAsyncThunk(
  "users/show",
  async (id: string, thunkAPI) => {
    try {
      const response = await userApi.show(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/update",
  async (
    {
      userId,
      params,
      formikBag,
    }: {
      userId: string;
      params: userApi.UserParams;
      formikBag: FormikHelpers<userApi.UserParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await userApi.update(userId, params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const inviteUser = createAsyncThunk(
  "users/invite",
  async (
    {
      params,
      formikBag,
    }: {
      params: userApi.UserInviteParams;
      formikBag: FormikHelpers<userApi.UserInviteParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await userApi.invite(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const reinviteUser = createAsyncThunk(
  "users/reinvite",
  async (userId: string, thunkAPI) => {
    try {
      const response = await userApi.reinvite(userId);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getInvitation = createAsyncThunk(
  "users/getInvitation",
  async (token: string, thunkAPI) => {
    try {
      const response = await userApi.getInvitation(token);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const acceptInvitation = createAsyncThunk(
  "users/acceptInvitation",
  async (
    {
      token,
      params,
      formikBag,
    }: {
      token: string;
      params: userApi.UserAcceptInvitationParams;
      formikBag: FormikHelpers<userApi.UserAcceptInvitationParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await userApi.acceptInvitation(token, params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchUserByToken = createAsyncThunk(
  "users/fetchUserByToken",
  async (_, thunkAPI) => {
    try {
      const response = await userApi.me();
      const data = response.data;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const authenticate = createAsyncThunk(
  "users/authenticate",
  async (
    {
      params,
    }: {
      params: userApi.AuthenticateParams;
    },
    thunkAPI
  ) => {
    try {
      const response = await userApi.authenticate(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        await saveToken({
          access_token: data.access_token,
          refresh_token: data.refresh_token,
        });
        const userResponse = await userApi.me();
        const userData = userResponse.data;
        if (userResponse.status === statusCodes.ok) {
          return userData;
        } else {
          clearLocalStorage();
          return thunkAPI.rejectWithValue(userData);
        }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateMyCurrentCompany = createAsyncThunk(
  "users/updateMyCurrentCompany",
  async (companyId: number | null, thunkAPI) => {
    try {
      const response = await userApi.updateMyCurrentCompany(companyId);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const transferOwnership = createAsyncThunk(
  "users/transferOwnership",
  async (userId: string, thunkAPI) => {
    try {
      const response = await userApi.transferOwnership(userId);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateCandidate = createAsyncThunk(
  "users/updateCandidate",
  async (
    {
      params,
      formikBag,
    }: {
      params: FormData;
      formikBag: FormikHelpers<userApi.UserCandidateParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await userApi.updateCandidate(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const logout = createAsyncThunk("users/logout", async (_, thunkAPI) => {
  try {
    const response = await userApi.logout();
    const data = response.data;
    if (response.status === statusCodes.ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const signUp = createAsyncThunk(
  "users/signUp",
  async (
    {
      params,
      formikBag,
    }: {
      params: userApi.SignUpParams;
      formikBag: FormikHelpers<userApi.SignUpParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await userApi.signUp(params);
      const { data } = response;
      if (response.status === statusCodes.created) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const candidateSignUp = createAsyncThunk(
  "users/candidateSignUp",
  async (
    {
      params,
      formikBag,
    }: {
      params: userApi.CandidateSignUpParams;
      formikBag: FormikHelpers<userApi.CandidateSignUpParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await userApi.candidateSignUp(params);
      const { data } = response;
      if (response.status === statusCodes.created) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
