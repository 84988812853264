import React from "react";
import { JobPipelinesAttributes } from "../../../redux/job/jobApi";
import CardContainer from "../ModalComponents/CardContainer";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { format } from "date-fns/format";
import { parseISO } from "date-fns/parseISO";
import { FormQuestionResponse } from "../../../utils/types";
import { formatDate } from "../../../utils/format";
import capitalize from "../../../utils/capitalize";
import { WorkExperience } from "../../MultiInput/WorkExperience";
import { Education } from "../../MultiInput/Education";

interface IProfessionalDetails {
  educations: Education[];
  workExperiences: WorkExperience[];
  questions?: FormQuestionResponse[];
}

const ProfessionalDetails: React.FC<IProfessionalDetails> = ({
  educations,
  workExperiences,
  questions,
}) => {
  const renderField = (name: string, value: React.ReactNode) => {
    return (
      <Grid item xs={12} md={12}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          {name}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>{value}</Typography>
      </Grid>
    );
  };

  const formatQuestionValue = (question: FormQuestionResponse) => {
    let value = null;
    if (question.type === "yes_no" && question.value) {
      value = question.value === "yes" ? "Sim" : "Não";
    }
    if (question.type === "date") {
      value = formatDate(question.value);
    }
    return question.value || "-";
  };

  const renderQuestions = () => {
    if (!questions || questions.length === 0) {
      return null;
    }

    return (
      <CardContainer title="Questões">
        <Grid container spacing={2}>
          {questions.map((question) =>
            renderField(question.name, formatQuestionValue(question))
          )}
        </Grid>
      </CardContainer>
    );
  };

  const renderEducations = () => {
    if (educations.length === 0) {
      return null;
    }

    return (
      <CardContainer title="Educação">
        {educations.map((education, index) => {
          const startDate = education.start_date
            ? format(parseISO(education.start_date), "yyyy")
            : null;
          const endDate = education.end_date
            ? `- ${format(parseISO(education.end_date), "yyyy")}`
            : null;

          return (
            <Box>
              {index !== 0 && <Divider sx={{ my: 2 }} />}
              {education.school ? (
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  {education.school}
                </Typography>
              ) : null}
              {education.degree || education.field_of_study ? (
                <Typography sx={{ fontSize: 14 }}>
                  {education.degree}
                  {education.field_of_study && education.degree ? ", " : ""}
                  {education.field_of_study}
                </Typography>
              ) : null}
              {education.start_date || education.end_date ? (
                <Typography
                  sx={{
                    fontSize: 14,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  {startDate} {endDate}
                </Typography>
              ) : null}
            </Box>
          );
        })}
      </CardContainer>
    );
  };

  const renderWorkExperiences = () => {
    if (workExperiences.length === 0) {
      return null;
    }

    return (
      <CardContainer title="Experiência Profissional">
        {workExperiences.map((work_experience, index) => {
          const startDate = work_experience.start_date
            ? capitalize(
                format(parseISO(work_experience.start_date), "MMM yyyy")
              )
            : null;
          const endDate = work_experience.end_date
            ? `- ${capitalize(
                format(parseISO(work_experience.end_date), "MMM yyyy")
              )}`
            : work_experience.is_current
            ? "- Presente"
            : null;

          return (
            <Box>
              {index !== 0 && <Divider sx={{ my: 2 }} />}
              {work_experience.title ? (
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  {work_experience.title}
                </Typography>
              ) : null}
              {work_experience.company ? (
                <Typography sx={{ fontSize: 14 }}>
                  {work_experience.company}
                </Typography>
              ) : null}
              {work_experience.start_date || work_experience.end_date ? (
                <Typography
                  sx={{
                    fontSize: 14,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  {startDate} {endDate}
                </Typography>
              ) : null}
              {work_experience.description ? (
                <Typography sx={{ fontSize: 14, mt: 1 }}>
                  {work_experience.description}
                </Typography>
              ) : null}
            </Box>
          );
        })}
      </CardContainer>
    );
  };

  return (
    <>
      {renderWorkExperiences()}
      {renderEducations()}
      {renderQuestions()}
    </>
  );
};

export default ProfessionalDetails;
