import React, { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { candidateNoteSelector } from "../../../redux/candidateNote/candidateNoteSlice";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { format, parseISO } from "date-fns";
import capitalize from "../../../utils/capitalize";
import { Delete, Edit, Send } from "@mui/icons-material";
import {
  createCandidateNote,
  destroyCandidateNote,
  getCandidateNotes,
  updateCandidateNote,
} from "../../../redux/candidateNote/candidateNoteActions";
import {
  CandidateNoteIndexAttributes,
  CandidateNoteParams,
} from "../../../redux/candidateNote/candidateNoteApi";
import { toast } from "react-toastify";
import { userSelector } from "../../../redux/user/userSlice";
import { LoadingButton } from "@mui/lab";
import useViewportHeight from "../../../hooks/useViewportHeight";

interface IHistory {
  id: number;
  jobIsCompleted: boolean;
}

const History: React.FC<IHistory> = ({ id, jobIsCompleted }) => {
  const dispatch = useAppDispatch();
  const candidateNoteState = useAppSelector(candidateNoteSelector);
  const containerRef = useRef<HTMLDivElement>(null);

  const { offsetTop } = useViewportHeight(containerRef);
  const userState = useAppSelector(userSelector);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const formik = useFormik({
    initialValues: {
      ...candidateNoteState.initialValues,
      candidate_id: id,
    } as CandidateNoteParams,
    onSubmit: (values, formikBag) => {
      if (values.note && values.note.trim() !== "") {
        if (values.id) {
          dispatch(updateCandidateNote({ params: values, formikBag })).then(
            (value) => {
              if (value.meta.requestStatus === "rejected") {
                toast.error(
                  "Não foi possível atualizar a nota, tente novamente!"
                );
              } else {
                dispatch(getCandidateNotes(id));
                formik.setFieldValue("note", "");
              }
            }
          );
        } else {
          dispatch(createCandidateNote({ params: values, formikBag })).then(
            (value) => {
              if (value.meta.requestStatus === "rejected") {
                toast.error("Não foi possível enviar a nota, tente novamente!");
              } else {
                dispatch(getCandidateNotes(id));
                formik.setFieldValue("note", "");
              }
            }
          );
        }
      }
    },
    enableReinitialize: true,
  });

  const startEditingNote = (note: CandidateNoteIndexAttributes) => {
    formik.setFieldValue("note", note.note);
    formik.setFieldValue("id", note.id);
  };

  const formatDate = (date: string): string => {
    return capitalize(format(parseISO(date), "MMM dd, yyyy HH:mm"));
  };

  const handleOpenDeleteDialog = (id: number) => {
    setIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIdToDelete(null);
  };

  const handleDelete = async () => {
    if (idToDelete) {
      await dispatch(destroyCandidateNote(idToDelete.toString())).then(
        (value) => {
          if (value.meta.requestStatus !== "rejected") {
            toast.success("Nota excluída com sucesso");
            dispatch(getCandidateNotes(id));
          }
        }
      );
    }
    handleCloseDeleteDialog();
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        height: `calc(100vh - ${offsetTop + 20}px)`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirmar exclusão</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja excluir esta nota?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseDeleteDialog}>
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              onClick={handleDelete}
              color="error"
              loading={candidateNoteState.isDestroying}
            >
              Excluir
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {candidateNoteState.candidateNotes.map((note, index) => (
          <Box key={note.id}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                {note.user ? (
                  <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                    {note.user.name}
                  </Typography>
                ) : null}
                <Typography
                  sx={{
                    fontSize: 14,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  {formatDate(note.created_at)}
                  {note.edited_at
                    ? ` • Atualizado em ${formatDate(note.edited_at)}`
                    : null}
                </Typography>
              </Box>
              {note.user.id === userState.currentUser.id && !jobIsCompleted ? (
                <Box sx={{ display: "flex", pr: 2 }}>
                  <Tooltip title="Atualizar">
                    <IconButton
                      size="small"
                      onClick={() => startEditingNote(note)}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Excluir">
                    <IconButton
                      size="small"
                      onClick={() => handleOpenDeleteDialog(note.id)}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : null}
            </Box>
            <Typography sx={{ fontSize: 15, my: 1 }}>{note.note}</Typography>
            {index !== 0 ? <Divider sx={{ my: 1 }} /> : null}
          </Box>
        ))}
      </Box>
      {!jobIsCompleted ? (
        <Box sx={{ mt: 1, position: "relative" }}>
          <TextField
            type="text"
            placeholder="Digite sua nota aqui..."
            fullWidth
            multiline
            rows={4}
            onChange={formik.handleChange("note")}
            onBlur={formik.handleBlur("note")}
            value={formik.values.note}
            inputProps={{
              sx: {
                pr: 4,
              },
            }}
          />

          {candidateNoteState.isSaving ? (
            <Box sx={{ position: "absolute", right: 12, bottom: 8 }}>
              <CircularProgress size={26} />
            </Box>
          ) : (
            <Box sx={{ position: "absolute", right: 4, bottom: 4 }}>
              <Tooltip title="Enviar">
                <IconButton
                  color="primary"
                  onClick={() => formik.handleSubmit()}
                >
                  <Send />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default History;
