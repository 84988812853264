import React, { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Google, LinkedIn } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { googleSignInUrl, linkedinSignInUrl } from "../../utils/oauth";
import { toast } from "react-toastify";
import { saveToken } from "../../utils/localStorage";
import urls from "../../utils/urls";
import LoginCardContainer from "../../components/LoginCardContainer";
import { colors } from "../../assets/themes";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { clearState, userSelector } from "../../redux/user/userSlice";
import PasswordInput from "../../components/Input/PasswordInput";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import Yup from "../../utils/yup";
import { authenticate } from "../../redux/user/userActions";

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const access_token = queryParams.get("access_token");
  const refresh_token = queryParams.get("refresh_token");
  const return_url = queryParams.get("return_url");
  const error = queryParams.get("error");

  const { isLoading, errorMessage } = useAppSelector(userSelector);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    handleOauthLogin();
  }, [access_token, refresh_token, return_url]);

  useEffect(() => {
    if (error) {
      toast.error("Não foi possível completar o login, tente novamente!");
    }
  }, [error]);

  const handleOauthLogin = async () => {
    if (access_token && refresh_token) {
      await saveToken({
        access_token,
        refresh_token,
      });

      if (return_url) {
        navigate(return_url);
      } else {
        navigate(urls.adminCandidateMyApplications);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(
        authenticate({ params: { ...values, is_candidate: true } })
      ).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          navigate(urls.adminCandidateMyApplications);
        }
      });
    },
    enableReinitialize: true,
  });

  return (
    <LoginCardContainer>
      <Typography
        color={colors.grey}
        fontSize={18}
        fontWeight="600"
        sx={{ mb: 1 }}
      >
        Bem vindo a Reazy!
      </Typography>

      <Typography color={colors.grey} fontSize={14} fontWeight="400">
        Por favor, faça o login para se conectar com a melhor plataforma de
        recrutamento
      </Typography>

      {errorMessage ? (
        <Alert severity="error" aria-live="assertive" sx={{ mb: 4, mt: 2 }}>
          {errorMessage}
        </Alert>
      ) : (
        <></>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <FormGroup sx={{ marginTop: 2 }}>
          <FormControl fullWidth>
            <TextField
              id="email"
              type="email"
              label="E-mail"
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              value={formik.values.email}
              error={!!(formik.touched.email && formik.errors.email)}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </FormControl>
        </FormGroup>

        <FormGroup sx={{ marginTop: 2 }}>
          <FormControl fullWidth>
            <PasswordInput
              id="password"
              label="Senha"
              onChange={formik.handleChange("password")}
              onBlur={formik.handleBlur("password")}
              value={formik.values.password}
              error={!!(formik.touched.password && formik.errors.password)}
              helperText={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null
              }
            />
          </FormControl>
        </FormGroup>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Link to={urls.forgotPassword}>
            <Typography
              fontSize={14}
              sx={{ mt: 1, color: theme.palette.primary.main }}
            >
              Esqueceu a Senha?
            </Typography>
          </Link>
        </Box>

        <FormGroup sx={{ marginTop: 4 }}>
          <FormControl fullWidth>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              type="submit"
              size="large"
            >
              Entrar
            </LoadingButton>
          </FormControl>
        </FormGroup>
      </form>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          mt: 8,
          mb: 4,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Link to={googleSignInUrl(return_url)}>
            <Button
              variant="outlined"
              size="large"
              startIcon={<Google />}
              sx={{ width: "100%" }}
            >
              Continuar com Google
            </Button>
          </Link>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Link to={linkedinSignInUrl(return_url)}>
            <Button
              variant="outlined"
              size="large"
              startIcon={<LinkedIn />}
              sx={{ width: "100%" }}
            >
              Continuar com LinkedIn
            </Button>
          </Link>
        </Box>
      </Box>

      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <Typography fontSize={14} color={colors.grey}>
          Novo na nossa plataforma?{" "}
          <Link
            to={urls.candidateSignup}
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
            }}
          >
            Crie uma conta
          </Link>
        </Typography>
      </Box>

      <Box sx={{ marginTop: 2, textAlign: "center" }}>
        <Typography fontSize={14}>
          <Link
            to={urls.login}
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
            }}
          >
            Logar como empresa
          </Link>
        </Typography>
      </Box>
    </LoginCardContainer>
  );
};

export default Login;
