import { createAsyncThunk } from "@reduxjs/toolkit";
import * as resumeDatabaseApi from "./resumeDatabaseApi";
import statusCodes from "../../utils/statusCodes";

export const getResumeDatabases = createAsyncThunk(
  "resumeDatabases/index",
  async (_params, thunkAPI) => {
    try {
      const response = await resumeDatabaseApi.index();
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
