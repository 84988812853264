import {
  Box,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";
import TableContainer from "../components/TableContainer";
import Table from "../components/Table";
import { GridActionsCellItem, GridColDef, GridRowId } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../app/store";
import { useEffect, useRef, useState } from "react";
import { resumeDatabaseSelector } from "../redux/resumeDatabase/resumeDatabaseSlice";
import { getResumeDatabases } from "../redux/resumeDatabase/resumeDatabaseActions";
import { Link } from "react-router-dom";
import urls from "../utils/urls";
import ResumeDatabaseModal from "../components/Candidate/ResumeDatabaseModal";
import { ResumeDatabaseIndexAttributes } from "../redux/resumeDatabase/resumeDatabaseApi";
import { Email, MoreVert, Share } from "@mui/icons-material";
import MenuDropdown, {
  IMenuDropdownRef,
  MenuItemDropdown,
} from "../components/MenuDropdown";
import CopyToClipboard from "react-copy-to-clipboard";

const ResumeDatabase = () => {
  const dispatch = useAppDispatch();

  const actionRefs = useRef<{ [key: GridRowId]: IMenuDropdownRef | null }>({});
  const searchParams = new URLSearchParams(location.search);
  const candidateId = searchParams.get("candidate_id");

  const resumeDatabaseState = useAppSelector(resumeDatabaseSelector);

  const [candidateSelectedData, setCandidateSelectedData] = useState<{
    candidate: ResumeDatabaseIndexAttributes;
  } | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getResumeDatabases());
  }, []);

  useEffect(() => {
    if (resumeDatabaseState.resumeDatabases.length > 0 && candidateId) {
      const candidate = resumeDatabaseState.resumeDatabases.find(
        (candidate) => candidate.id === parseInt(candidateId)
      );
      if (candidate) {
        setCandidateSelectedData({ candidate: candidate });
      }
    }
  }, [resumeDatabaseState.resumeDatabases, candidateId]);

  const candidateProfileUrl = (id: number) => {
    return `${window.location.origin}${urls.resumeDatabase}?candidate_id=${id}`;
  };

  const handleCopyUrl = () => {
    setSnackbarOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => {
        return (
          <Box
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => setCandidateSelectedData({ candidate: params.row })}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "E-mail",
      width: 250,
    },
    {
      field: "job",
      headerName: "Título da última vaga",
      width: 400,
      renderCell: (params) => {
        return params.value.deleted ? (
          params.value.name
        ) : (
          <Link
            to={urls.manageJob.replace(":id", params.value.id.toString())}
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            {params.value.name}
          </Link>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<MoreVert />}
            label="Show"
            className="textPrimary"
            onClick={(e) => actionRefs?.current[id]?.open(e)}
          />,
          <MenuDropdown
            id={`resume-database-action-${id}`}
            ref={(el) => (actionRefs.current[id] = el)}
          >
            <MenuItemDropdown
              component="a"
              href={`mailto:${row.email}`}
              target="_blank"
            >
              <ListItemIcon>
                <Email fontSize="small" />
              </ListItemIcon>
              <ListItemText>Enviar E-mail</ListItemText>
            </MenuItemDropdown>
            <CopyToClipboard
              text={candidateProfileUrl(row.id)}
              onCopy={handleCopyUrl}
            >
              <MenuItemDropdown>
                <ListItemIcon>
                  <Share fontSize="small" />
                </ListItemIcon>
                <ListItemText>Compartilhar Perfil</ListItemText>
              </MenuItemDropdown>
            </CopyToClipboard>
          </MenuDropdown>,
        ];
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h5">Banco de Currículos</Typography>

      <Snackbar
        message="Link Copiado"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        open={snackbarOpen}
      />

      <ResumeDatabaseModal
        candidateData={candidateSelectedData}
        onClose={() => setCandidateSelectedData(null)}
      />

      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <Table
            rows={resumeDatabaseState.resumeDatabases}
            columns={columns}
            loading={false}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ResumeDatabase;
