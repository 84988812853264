import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppDispatch, useAppSelector } from "../app/store";
import Yup from "../utils/yup";
import { clearState, userSelector } from "../redux/user/userSlice";
import { useFormik } from "formik";
import { candidateSignUp } from "../redux/user/userActions";
import LoginCardContainer from "../components/LoginCardContainer";
import { colors } from "../assets/themes";
import PasswordInput from "../components/Input/PasswordInput";
import urls from "../utils/urls";
import { Mail } from "@mui/icons-material";

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email().max(255).required(),
  first_name: Yup.string().max(255).required(),
  last_name: Yup.string().max(255).required(),
  password: Yup.string().min(8).max(255).required(),
  password_confirmation: Yup.string()
    .test("passwords-match", "Senhas não conferem", function (value) {
      return this.parent.password === value;
    })
    .required(),
});

const CandidateSignUp = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { isSaving, isSaveSuccess } = useAppSelector(userSelector);

  const [accountCreated, setAccountCreated] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isSaveSuccess) {
      setAccountCreated(true);
    }
  }, [isSaveSuccess]);

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: SignUpSchema,
    onSubmit: (values, formikBag) => {
      dispatch(candidateSignUp({ params: values, formikBag }));
    },
    enableReinitialize: true,
  });

  if (accountCreated) {
    return (
      <LoginCardContainer maxWidth="sm">
        <Alert icon={<Mail fontSize="inherit" />} severity="info">
          Conta criada com sucesso, verifique sua caixa de mensagens para
          confirmar o e-mail
        </Alert>
      </LoginCardContainer>
    );
  }

  return (
    <LoginCardContainer maxWidth="sm">
      <Typography
        color={colors.grey}
        fontSize={18}
        fontWeight="600"
        sx={{ mb: 1 }}
      >
        Faça seu cadastro
      </Typography>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <FormGroup sx={{ marginTop: 2 }}>
          <FormControl fullWidth>
            <TextField
              type="email"
              label="E-mail"
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              value={formik.values.email}
              error={!!(formik.touched.email && formik.errors.email)}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </FormControl>
        </FormGroup>

        <FormGroup sx={{ marginTop: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
            }}
          >
            <FormControl fullWidth>
              <TextField
                type="text"
                label="Nome"
                onChange={formik.handleChange("first_name")}
                onBlur={formik.handleBlur("first_name")}
                value={formik.values.first_name}
                error={
                  !!(formik.touched.first_name && formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                    ? formik.errors.first_name
                    : null
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                type="text"
                label="Sobrenome"
                onChange={formik.handleChange("last_name")}
                onBlur={formik.handleBlur("last_name")}
                value={formik.values.last_name}
                error={!!(formik.touched.last_name && formik.errors.last_name)}
                helperText={
                  formik.touched.last_name && formik.errors.last_name
                    ? formik.errors.last_name
                    : null
                }
              />
            </FormControl>
          </Box>
        </FormGroup>

        <FormGroup sx={{ marginTop: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
            }}
          >
            <FormControl fullWidth>
              <PasswordInput
                label="Senha"
                onChange={formik.handleChange("password")}
                onBlur={formik.handleBlur("password")}
                value={formik.values.password}
                error={!!(formik.touched.password && formik.errors.password)}
                helperText={
                  formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : null
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <PasswordInput
                label="Confirmar Senha"
                onChange={formik.handleChange("password_confirmation")}
                onBlur={formik.handleBlur("password_confirmation")}
                value={formik.values.password_confirmation}
                error={
                  !!(
                    formik.touched.password_confirmation &&
                    formik.errors.password_confirmation
                  )
                }
                helperText={
                  formik.touched.password_confirmation &&
                  formik.errors.password_confirmation
                    ? formik.errors.password_confirmation
                    : null
                }
              />
            </FormControl>
          </Box>
        </FormGroup>

        <FormGroup sx={{ marginTop: 4 }}>
          <FormControl fullWidth>
            <LoadingButton
              variant="contained"
              loading={isSaving}
              type="submit"
              size="large"
            >
              Cadastrar
            </LoadingButton>
          </FormControl>
        </FormGroup>
      </form>

      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <Typography fontSize={14} color={colors.grey}>
          Já tem uma conta?{" "}
          <Link
            to={urls.candidateLogin}
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
            }}
          >
            Entre
          </Link>
        </Typography>
      </Box>
    </LoginCardContainer>
  );
};

export default CandidateSignUp;
