import React, { useRef } from "react";
import { JobPipelinesAttributes } from "../../../redux/job/jobApi";
import { Box } from "@mui/material";
import useViewportHeight from "../../../hooks/useViewportHeight";

interface IResume {
  resume: { name: string; url: string } | null;
}

const Resume: React.FC<IResume> = ({ resume }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { offsetTop } = useViewportHeight(containerRef);

  return resume ? (
    <Box
      ref={containerRef}
      sx={{
        width: "100%",
        height: `calc(100vh - ${offsetTop + 20}px)`,
      }}
    >
      <iframe
        src={resume.url}
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    </Box>
  ) : null;
};

export default Resume;
