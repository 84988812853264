import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Yup from "../utils/yup";
import { useFormik } from "formik";
import {
  Close,
  CloudUpload,
  ContentCopy,
  ExpandMore,
  Fullscreen,
} from "@mui/icons-material";
import { MuiColorInput } from "mui-color-input";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Company from "./CompanyPage";
import { useAppDispatch, useAppSelector } from "../app/store";
import { clearState, companySelector } from "../redux/company/companySlice";
import { showCompany, updateCompany } from "../redux/company/companyActions";
import { userSelector } from "../redux/user/userSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { CompanyParams } from "../redux/company/companyApi";
import { toast } from "react-toastify";
import Editor from "../components/Editor";
import CropperModal from "../components/CropperModal";
import IFrame from "../components/IFrame";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";
import VisuallyHiddenInput from "../components/Input/VisuallyHiddenInput";

const APP_URL = process.env.REACT_APP_URL;

const settingsSchema = Yup.object().shape({
  slug: Yup.string().required(),
  website: Yup.string().nullable(),
  linkedin: Yup.string().nullable(),
  facebook: Yup.string().nullable(),
  instagram: Yup.string().nullable(),
  twitter: Yup.string().nullable(),
  glassdoor: Yup.string().nullable(),
  primary_color: Yup.string(),
  name: Yup.string().nullable(),
  slogan: Yup.string().nullable(),
  description_title: Yup.string().nullable(),
  description: Yup.string().nullable(),
  logo: Yup.mixed().nullable(),
  banner: Yup.mixed().nullable(),
});

function Website() {
  const dispatch = useAppDispatch();

  const hasPermissionToCompanyUpdate = usePermission("company.update");

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [urlExpanded, setUrlExpanded] = useState<boolean>(true);
  const [textsExpanded, setTextsExpanded] = useState<boolean>(true);
  const [logoPreview, setLogoPreview] = useState<string | ArrayBuffer | null>(
    null
  );
  const [bannerPreview, setBannerPreview] = useState<
    string | ArrayBuffer | null
  >(null);
  const [logoCropperOpen, setLogoCropperOpen] = useState<boolean>(false);
  const [bannerCropperOpen, setBannerCropperOpen] = useState<boolean>(false);
  const [previewFullscreen, setPreviewFullscreen] = useState<boolean>(false);

  const companyState = useAppSelector(companySelector);
  const userState = useAppSelector(userSelector);

  useEffect(() => {
    if (userState.currentUser.company?.id) {
      dispatch(showCompany(userState.currentUser.company.id.toString()));
    }
  }, [userState.currentUser.company?.id]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (companyState.isSaveSuccess) {
      toast.success("Configuração salva com sucesso");
    }
  }, [companyState.isSaveSuccess]);

  const formik = useFormik<CompanyParams>({
    initialValues: (companyState.company ??
      companyState.initialValues) as CompanyParams,
    validationSchema: settingsSchema,
    onSubmit: (values, formikBag) => {
      const formData = new FormData();
      if (userState.currentUser.company) {
        formData.append("id", userState.currentUser.company.id.toString());
      }
      formData.append("company[slug]", values.slug);
      formData.append("company[visible]", values.visible ? "true" : "false");
      formData.append("company[primary_color]", values.primary_color);
      if (values.website) {
        formData.append("company[website]", values.website);
      }
      if (values.linkedin) {
        formData.append("company[linkedin]", values.linkedin);
      }
      if (values.facebook) {
        formData.append("company[facebook]", values.facebook);
      }
      if (values.instagram) {
        formData.append("company[instagram]", values.instagram);
      }
      if (values.twitter) {
        formData.append("company[twitter]", values.twitter);
      }
      if (values.glassdoor) {
        formData.append("company[glassdoor]", values.glassdoor);
      }
      if (values.name) {
        formData.append("company[name]", values.name);
      }
      if (values.slogan) {
        formData.append("company[slogan]", values.slogan);
      }
      if (values.description_title) {
        formData.append("company[description_title]", values.description_title);
      }
      if (values.description) {
        formData.append("company[description]", values.description);
      }
      if (values.logo instanceof File) {
        formData.append("company[logo]", values.logo);
      }
      if (values.banner instanceof File) {
        formData.append("company[banner]", values.banner);
      }
      dispatch(
        updateCompany({
          formData,
          formikBag,
        })
      );
    },
    enableReinitialize: true,
  });

  const handleCopyUrl = () => {
    setSnackbarOpen(true);
  };

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("logo", file);

      const reader = new FileReader();

      reader.onload = function (e) {
        if (e.target?.result) {
          setLogoPreview(e.target.result);
          setLogoCropperOpen(true);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoCropped = (file: File) => {
    formik.setFieldValue("logo", file);

    const reader = new FileReader();

    reader.onload = function (e) {
      if (e.target?.result) {
        setLogoPreview(e.target.result);
      }
      setLogoCropperOpen(false);
    };
    reader.readAsDataURL(file);
  };

  const handleBannerChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("banner", file);

      const reader = new FileReader();

      reader.onload = function (e) {
        if (e.target?.result) {
          setBannerPreview(e.target.result);
          setBannerCropperOpen(true);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerCropped = (file: File) => {
    formik.setFieldValue("banner", file);

    const reader = new FileReader();

    reader.onload = function (e) {
      if (e.target?.result) {
        setBannerPreview(e.target.result);
      }
      setBannerCropperOpen(false);
    };
    reader.readAsDataURL(file);
  };

  const handlePasteOnSocialMedia = (
    e: React.ClipboardEvent<HTMLDivElement>,
    field: string,
    text: string
  ) => {
    e.preventDefault();

    const pastedText = e.clipboardData.getData("text").split(text);
    formik.setFieldValue(field, pastedText[pastedText.length - 1]);
  };

  const renderPreview = (id: string) => {
    return (
      <IFrame id={id}>
        <Company
          isPreview
          previewData={{
            ...formik.values,
            logo: logoPreview || companyState.company?.logo || null,
            banner: bannerPreview || companyState.company?.banner || null,
            jobs: [],
          }}
        />
      </IFrame>
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container spacing={2} sx={{ mb: 6 }}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ mb: 2, p: { xs: 1, sm: 2 } }}>
            <Typography variant="h5">Configurações</Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Accordion
              expanded={urlExpanded}
              onChange={() => setUrlExpanded(!urlExpanded)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontWeight={"bold"}>URL da Página</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="URL da Página"
                      size="small"
                      required
                      onChange={formik.handleChange("slug")}
                      onBlur={formik.handleBlur("slug")}
                      value={formik.values.slug}
                      error={!!(formik.touched.slug && formik.errors.slug)}
                      helperText={
                        formik.touched.slug && formik.errors.slug
                          ? formik.errors.slug
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {`${APP_URL}/`}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <CopyToClipboard
                                text={`${APP_URL}/${formik.values.slug}`}
                                onCopy={handleCopyUrl}
                              >
                                <Tooltip title="Copiar">
                                  <ContentCopy />
                                </Tooltip>
                              </CopyToClipboard>
                            </IconButton>
                            <Snackbar
                              message="Copiado"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              autoHideDuration={2000}
                              onClose={() => setSnackbarOpen(false)}
                              open={snackbarOpen}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </FormGroup>

                <FormGroup sx={{ mt: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.visible}
                        onChange={(e, checked) => {
                          formik.setFieldValue("visible", checked);
                          formik.setFieldTouched("visible", true);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={"Visível na Internet"}
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={textsExpanded}
              onChange={() => setTextsExpanded(!textsExpanded)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontWeight={"bold"}>
                  Textos de Apresentação
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Nome da empresa"
                      size="small"
                      onChange={formik.handleChange("name")}
                      onBlur={formik.handleBlur("name")}
                      value={formik.values.name}
                      error={!!(formik.touched.name && formik.errors.name)}
                      helperText={
                        formik.touched.name && formik.errors.name
                          ? formik.errors.name
                          : null
                      }
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Slogan"
                      size="small"
                      onChange={formik.handleChange("slogan")}
                      onBlur={formik.handleBlur("slogan")}
                      value={formik.values.slogan}
                      error={!!(formik.touched.slogan && formik.errors.slogan)}
                      helperText={
                        formik.touched.slogan && formik.errors.slogan
                          ? formik.errors.slogan
                          : null
                      }
                    />
                  </FormControl>
                </FormGroup>

                <FormGroup sx={{ marginTop: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Título da descrição da empresa"
                      size="small"
                      onChange={formik.handleChange("description_title")}
                      onBlur={formik.handleBlur("description_title")}
                      value={formik.values.description_title}
                      error={
                        !!(
                          formik.touched.description_title &&
                          formik.errors.description_title
                        )
                      }
                      helperText={
                        formik.touched.description_title &&
                        formik.errors.description_title
                          ? formik.errors.description_title
                          : null
                      }
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                  <FormControl fullWidth>
                    <Editor
                      id="description"
                      label="Descrição da empresa"
                      value={formik.values.description || ""}
                      onEditorChange={(newValue, editor) => {
                        formik.setFieldValue("description", newValue);
                      }}
                    />
                  </FormControl>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontWeight={"bold"}>Identidade Visual</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 4 }}>
                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<CloudUpload />}
                  >
                    Enviar Logo
                    <VisuallyHiddenInput
                      type="file"
                      accept=".jpeg, .jpg, .png"
                      onChange={handleLogoChange}
                    />
                  </Button>
                  <CropperModal
                    title="Ajustar Logo"
                    image={logoPreview?.toString()}
                    open={logoCropperOpen}
                    onClose={() => setLogoCropperOpen(false)}
                    onCrop={handleLogoCropped}
                    cropperProps={{
                      cropShape: "round",
                      restrictPosition: false,
                    }}
                  />

                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<CloudUpload />}
                  >
                    Enviar Banner
                    <VisuallyHiddenInput
                      type="file"
                      accept=".jpeg, .jpg, .png"
                      onChange={handleBannerChange}
                    />
                  </Button>
                  <CropperModal
                    title="Ajustar Banner"
                    image={bannerPreview?.toString()}
                    open={bannerCropperOpen}
                    onClose={() => setBannerCropperOpen(false)}
                    onCrop={handleBannerCropped}
                    cropperProps={{ aspect: 3.3 }}
                  />
                </Box>
                <MuiColorInput
                  label="Cor Primária"
                  value={formik.values.primary_color}
                  onChange={formik.handleChange("primary_color")}
                  error={
                    !!(
                      formik.touched.primary_color &&
                      formik.errors.primary_color
                    )
                  }
                  helperText={
                    formik.touched.primary_color && formik.errors.primary_color
                      ? formik.errors.primary_color
                      : null
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontWeight={"bold"}>Redes Sociais</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Website"
                      size="small"
                      onChange={formik.handleChange("website")}
                      onBlur={formik.handleBlur("website")}
                      value={formik.values.website}
                      error={
                        !!(formik.touched.website && formik.errors.website)
                      }
                      helperText={
                        formik.touched.website && formik.errors.website
                          ? formik.errors.website
                          : null
                      }
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="LinkedIn"
                      size="small"
                      onChange={formik.handleChange("linkedin")}
                      onBlur={formik.handleBlur("linkedin")}
                      onPaste={(e) =>
                        handlePasteOnSocialMedia(
                          e,
                          "linkedin",
                          "https://www.linkedin.com/company/"
                        )
                      }
                      value={formik.values.linkedin}
                      error={
                        !!(formik.touched.linkedin && formik.errors.linkedin)
                      }
                      helperText={
                        formik.touched.linkedin && formik.errors.linkedin
                          ? formik.errors.linkedin
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            https://www.linkedin.com/company/
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Facebook"
                      size="small"
                      onChange={formik.handleChange("facebook")}
                      onBlur={formik.handleBlur("facebook")}
                      onPaste={(e) =>
                        handlePasteOnSocialMedia(
                          e,
                          "facebook",
                          "https://www.facebook.com/"
                        )
                      }
                      value={formik.values.facebook}
                      error={
                        !!(formik.touched.facebook && formik.errors.facebook)
                      }
                      helperText={
                        formik.touched.facebook && formik.errors.facebook
                          ? formik.errors.facebook
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            https://www.facebook.com/
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Instagram"
                      size="small"
                      onChange={formik.handleChange("instagram")}
                      onBlur={formik.handleBlur("instagram")}
                      onPaste={(e) =>
                        handlePasteOnSocialMedia(
                          e,
                          "instagram",
                          "https://www.instagram.com/"
                        )
                      }
                      value={formik.values.instagram}
                      error={
                        !!(formik.touched.instagram && formik.errors.instagram)
                      }
                      helperText={
                        formik.touched.instagram && formik.errors.instagram
                          ? formik.errors.instagram
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            https://www.instagram.com/
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Twitter"
                      size="small"
                      onChange={formik.handleChange("twitter")}
                      onBlur={formik.handleBlur("twitter")}
                      onPaste={(e) =>
                        handlePasteOnSocialMedia(
                          e,
                          "twitter",
                          "https://www.twitter.com/"
                        )
                      }
                      value={formik.values.twitter}
                      error={
                        !!(formik.touched.twitter && formik.errors.twitter)
                      }
                      helperText={
                        formik.touched.twitter && formik.errors.twitter
                          ? formik.errors.twitter
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            https://www.twitter.com/
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Glassdoor"
                      size="small"
                      onChange={formik.handleChange("glassdoor")}
                      onBlur={formik.handleBlur("glassdoor")}
                      onPaste={(e) =>
                        handlePasteOnSocialMedia(
                          e,
                          "glassdoor",
                          "https://www.glassdoor.com/"
                        )
                      }
                      value={formik.values.glassdoor}
                      error={
                        !!(formik.touched.glassdoor && formik.errors.glassdoor)
                      }
                      helperText={
                        formik.touched.glassdoor && formik.errors.glassdoor
                          ? formik.errors.glassdoor
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            https://www.glassdoor.com/
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
                pb: 0,
              }}
            >
              <Typography variant="h5">Pré-visualização</Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setPreviewFullscreen(true)}
              >
                <Tooltip title="Tela Cheia">
                  <Fullscreen />
                </Tooltip>
              </IconButton>

              <Dialog
                fullWidth
                maxWidth="xl"
                open={previewFullscreen}
                onClose={() => setPreviewFullscreen(false)}
              >
                <DialogTitle>Pré-visualização</DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={() => setPreviewFullscreen(false)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
                {renderPreview("preview-fullscreen")}
              </Dialog>
            </Box>
            <Divider sx={{ mt: 1 }} />
            {renderPreview("preview")}
          </Paper>
        </Grid>
      </Grid>

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          backgroundColor: (theme) => theme.palette.common.white,
          width: "100%",
          ml: "-24px",
          pl: "24px",
          pb: 2,
          zIndex: 1,
        }}
      >
        <Tooltip
          title={!hasPermissionToCompanyUpdate ? texts.notAuthorized : ""}
        >
          <Box sx={{ mt: 2, width: "fit-content" }}>
            <LoadingButton
              variant="contained"
              size="large"
              loading={companyState.isSaving}
              onClick={() => formik.handleSubmit()}
              disabled={!hasPermissionToCompanyUpdate}
              sx={{ p: "10px 30px" }}
            >
              Salvar
            </LoadingButton>
          </Box>
        </Tooltip>
      </Paper>
    </Box>
  );
}

export default Website;
