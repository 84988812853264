import { createSlice } from "@reduxjs/toolkit";
import { getResumeDatabases } from "./resumeDatabaseActions";
import { RootState } from "../../app/store";
import { ResumeDatabaseIndexAttributes } from "./resumeDatabaseApi";

export interface initialState {
  resumeDatabases: ResumeDatabaseIndexAttributes[];
  isFetching: boolean;
  isFinding: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
}

export const resumeDatabaseSlice = createSlice({
  name: "resumeDatabase",
  initialState: {
    resumeDatabases: [],
    isFetching: false,
    isFinding: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isFinding = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getResumeDatabases.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getResumeDatabases.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.resumeDatabases = payload.records;
    });
    builder.addCase(getResumeDatabases.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.resumeDatabases = [];
    });
  },
});

export const { clearState } = resumeDatabaseSlice.actions;

export const resumeDatabaseSelector = (state: RootState) =>
  state.resumeDatabase;
