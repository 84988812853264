import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Snackbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import { FormikProps } from "formik";
import { JobParams } from "../../redux/job/jobApi";
import urls from "../../utils/urls";
import { useAppSelector } from "../../app/store";
import { userSelector } from "../../redux/user/userSlice";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { ContentCopy } from "@mui/icons-material";

interface IJobPromotion {
  formik: FormikProps<JobParams>;
}

const JobPromotion: React.FC<IJobPromotion> = ({ formik }) => {
  const userState = useAppSelector(userSelector);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const jobUrl = () => {
    if (!formik.values.id) {
      return "";
    }

    return `${window.location.origin}${urls.companyJobPage
      .replace(":slug", userState.currentUser.company?.slug || "")
      .replace(":job_id", formik.values.id.toString())}`;
  };

  const handleCopyUrl = () => {
    setSnackbarOpen(true);
  };

  return (
    <Accordion expanded>
      <AccordionSummary sx={{ cursor: "auto !important" }}>
        <Typography fontWeight={"bold"}>Divulgação</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Snackbar
            message="Link Copiado"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
            open={snackbarOpen}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography>Link da vaga:</Typography>
            {formik.values.status === "published" ? (
              <>
                <Link
                  to={jobUrl()}
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  <Typography>{jobUrl()}</Typography>
                </Link>
                <IconButton>
                  <CopyToClipboard text={jobUrl()} onCopy={handleCopyUrl}>
                    <Tooltip title="Copiar">
                      <ContentCopy />
                    </Tooltip>
                  </CopyToClipboard>
                </IconButton>
              </>
            ) : (
              <Typography>Publique a vaga para o link ser criado</Typography>
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default JobPromotion;
